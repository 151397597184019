import { ConsentAltBox } from '../components/consent-alt-box/consent-alt-box.component'
import { type ConsentBoxAltType } from '../components/consent-alt-box/consent-alt-box.model'
import { type PublicApi } from '../models/api.model'
import { type SupportedLanguages } from '../models/app-translations.model'
import { type CookieConsentCategory } from '../models/consent-data.model'
import { type TranslateConfig } from '../models/translate.service.model'
import { AppInitializerService } from './app-initializer.service'
import { ConsentModalService } from './consent-modal.service'

declare global {
  interface Window {
    DPM_COOKIE_INFO2: PublicApi
  }
}

export class ApiService {
  private static instance: ApiService
  private readonly appInitializerService: AppInitializerService
  private readonly consentModalService: ConsentModalService

  private constructor() {
    this.appInitializerService = AppInitializerService.getInstance()
    this.consentModalService = ConsentModalService.getInstance()
  }

  static getInstance(): ApiService {
    if (ApiService.instance === undefined) {
      ApiService.instance = new ApiService()
    }

    return ApiService.instance
  }

  createPublicObject(): void {
    window.DPM_COOKIE_INFO2 = this.getInitialConfiguration()
  }

  updateConsentItems(items: CookieConsentCategory[]): void {
    window.DPM_COOKIE_INFO2.categories = items
  }

  private getInitialConfiguration(): PublicApi {
    return {
      categories: [],
      setApiUrl: url => {
        this.setApiUrl(url)
      },
      setWidgetCommand: command => {
        this.setWidgetCommand(command)
      },
      setInterfaceLang: lang => {
        this.setInterfaceLang(lang)
      },
      getAlternative: type => {
        return this.getAlternativeContent(type)
      },
      setTranslationConfig: translateConfig => {
        this.setTranslationConfig(translateConfig)
      },
      setIntegrationWithGTM: enable => {
        this.setIntegrationWithGTM(enable)
      },
      displayStickyTrigger: visible => {
        this.displayStickyTrigger(visible)
      },
      displayFirstStepRejectAllButton: show => {
        this.displayFirstStepRejectAllButton(show)
      },
      displayCloseButton: show => {
        this.displayCloseButton(show)
      },
      addCustomCssClass: className => {
        this.addCustomCssClass(className)
      },
      showModal: () => {
        this.showModal()
      },
      run: () => {
        this.runApplication()
      },
      runAllNodes: () => {
        this.runAllNodes()
      },
      areConsentsVerified: () => {
        return this.areConsentsVerified()
      },
      displayRejectAllButton: show => {
        this.displayFirstStepRejectAllButton(show)
      }
    }
  }

  private setApiUrl(url: string): void {
    this.appInitializerService.setApiUrl(url)
  }

  private setWidgetCommand(command: string): void {
    this.appInitializerService.setWidgetCommand(command)
  }

  private setInterfaceLang(lang: SupportedLanguages): void {
    this.appInitializerService.setInterfaceLang(lang)
  }

  private setTranslationConfig(config: TranslateConfig): void {
    this.appInitializerService.setTranslationConfig(config)
  }

  private setIntegrationWithGTM(enable: boolean): void {
    this.appInitializerService.setIntegrationWithGTM(enable)
  }

  private displayStickyTrigger(visible: boolean): void {
    this.appInitializerService.displayStickyTrigger(visible)
  }

  private displayFirstStepRejectAllButton(show: boolean): void {
    this.appInitializerService.displayFirstStepRejectAllButton(show)
  }

  private displayCloseButton(show: boolean): void {
    this.appInitializerService.displayCloseButton(show)
  }

  private addCustomCssClass(className: string): void {
    this.appInitializerService.addCustomCssClass(className)
  }

  private getAlternativeContent(type: ConsentBoxAltType): HTMLElement {
    const altBox = new ConsentAltBox(type)

    return altBox.getHTMLContent()
  }

  private showModal(): void {
    this.consentModalService.showModal()
  }

  private runApplication(): void {
    this.appInitializerService.init()
  }

  private runAllNodes(): void {
    this.appInitializerService.runAllNodes()
  }

  private areConsentsVerified(): boolean {
    return this.appInitializerService.areConsentsVerified()
  }
}
