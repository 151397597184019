import { filter, Subscription } from 'rxjs'

import { Component } from 'app/core/base-component'
import { ConsentGeneralTemplate } from './consent-general.template'
import { ConsentDataService } from 'app/services/consent-data.service'
import { AppConfigService } from 'app/services/app-config.service'
import { type CookieConsent } from 'app/models/consent-data.model'
import { ConsentGeneralEvent } from './consent-general.model'
import { ConsentWrapperService } from 'app/services/consent-wrapper.service'
import { ConsentWrapperStage } from 'app/models/consent-wrapper.model'

export class ConsentGeneral extends Component {
  private readonly templateRef: ConsentGeneralTemplate
  private readonly consentDataService: ConsentDataService
  private readonly consentWrapperService: ConsentWrapperService
  private readonly appConfigService: AppConfigService

  private data!: CookieConsent
  private eventSubscription!: Subscription
  private isDeclineConsentsEventRegistered = false
  private showRejectAllButton!: boolean

  constructor() {
    super({
      componentName: 'consent-general',
      componentClass: 'consent-modal2__general'
    })

    this.templateRef = new ConsentGeneralTemplate()
    this.consentDataService = ConsentDataService.getInstance()
    this.consentWrapperService = ConsentWrapperService.getInstance()
    this.appConfigService = AppConfigService.getInstance()

    this.init()
  }

  private init(): void {
    this.showRejectAllButton = this.appConfigService.getDisplayFirstStepRejectAllButtonState()
    this.addSubscription()
  }

  private addSubscription(): void {
    this.consentDataService.consentData$.pipe(filter(Boolean)).subscribe((data: CookieConsent) => {
      this.data = data

      this.renderContent(
        this.templateRef.getTemplate({
          consents: this.data,
          options: {
            showRejectAllBtn: this.showRejectAllButton
          }
        })
      )

      this.addEventsSubscription()

      if (!this.isDeclineConsentsEventRegistered) {
        setTimeout(() => {
          this.registerDeclineConsentsClickEvent()
        })
      }
    })
  }

  private registerDeclineConsentsClickEvent(): void {
    document.querySelectorAll('.consent-modal2__decline-consents-btn').forEach((inlineBtnElement: Element) => {
      const eventListener: EventListener = this.getDeclineConsentsEventListener()

      inlineBtnElement.addEventListener('click', eventListener)
      this.isDeclineConsentsEventRegistered = true
    })
  }

  private getDeclineConsentsEventListener(): EventListener {
    return (event: Event) => {
      event.preventDefault()
      event.stopPropagation()

      this.consentDataService.declineAllConsents()
    }
  }

  private addEventsSubscription(): void {
    if (this.eventSubscription === undefined) {
      this.eventSubscription = new Subscription()

      this.eventSubscription.add(
        this.templateRef.events[ConsentGeneralEvent.AcceptAll].subscribe(() => {
          this.consentDataService.acceptAllConsents()
        })
      )

      this.eventSubscription.add(
        this.templateRef.events[ConsentGeneralEvent.RejectAll].subscribe(() => {
          this.consentDataService.declineAllConsents()
        })
      )

      this.eventSubscription.add(
        this.templateRef.events[ConsentGeneralEvent.ManageOptions].subscribe(() => {
          this.consentWrapperService.switchStage(ConsentWrapperStage.Details)
        })
      )
    }
  }
}
